import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Text sx={{
      fontSize: [4, 5, 6],
      fontWeight: `bold`,
      color: `heading`
    }} mdxType="Text">
  Hi.
    </Text>
    <blockquote>
      <p parentName="blockquote">{`Not your keys, not your coins.`}<br parentName="p"></br>{`
`}{`Not your servers, not your data.`}</p>
    </blockquote>
    <p>{`I enjoy privacy and security.`}</p>
    <p>{`Web3.`}</p>
    <p>{`Let's connect: `}<a parentName="p" {...{
        "href": "mailto:janmakonline@outlook.com"
      }}>{`janmakonline@outlook.com`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      